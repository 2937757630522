import { Office365Integration } from '../../../types/integrations';
import { IntegrationCard } from './common/IntegrationCard';

interface Office365DetailsProps {
  integration: Office365Integration;
}

export const Office365Details = ({ integration }: Office365DetailsProps) => {
  return (
    <IntegrationCard
      status="Connected"
      details={[
        { label: 'Tenant Id', value: integration.config.azureTenantId },
        { label: 'Tenant Name', value: integration.config.name },
      ]}
      integration={integration}
    />
  );
};

export default Office365Details;
