import { CreateToggleBody, Toggle } from '../types/toggle';
import { cleanRequest } from '../utils/requestUtils';
import { api } from './api';
import { ListResponse } from './types';

export interface GetProjectTogglesBody {
  organizationId?: string;
  projectId?: string;
}

export const toggleApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createToggle: builder.mutation<Toggle, CreateToggleBody>({
      query: ({ key, description, tags, type, defaultValue, organizationId, projectId }) => ({
        url: `api/organizations/${organizationId}/projects/${projectId}/toggles/`,
        method: 'POST',
        body: {
          key,
          description,
          tags: [],
          type,
          targets: [],
          defaultValue,
        },
      }),
      invalidatesTags: [{ type: 'Toggles', id: 'LIST' }],
    }),
    getToggle: builder.query<Toggle, { organizationId: string; projectId: string; toggleKey: string }>({
      query: ({ organizationId, projectId, toggleKey }) => ({
        url: `api/organizations/${organizationId}/projects/${projectId}/toggles/${toggleKey}`,
        method: 'GET',
      }),
      providesTags: (result, _error, { projectId }) =>
        result ? [{ type: 'Toggles', id: `${projectId}-${result.key}` }] : [],
    }),
    getToggles: builder.query<ListResponse<Toggle>, GetProjectTogglesBody>({
      query: ({ organizationId, projectId }) => ({
        url: `api/organizations/${organizationId}/projects/${projectId}/toggles/`,
        method: 'GET',
      }),
      providesTags: (result = { data: [], pageNum: 1, pageSize: 200, total: 200 }, _error, { projectId }) => {
        return [
          ...result.data.map(({ key }) => ({ type: 'Toggles' as const, id: `${projectId}-${key}` })),
          { type: 'Toggles', id: 'TOGGLE-LIST' },
        ];
      },
    }),
    updateToggleTargets: builder.mutation<
      Toggle,
      { organizationId: string; projectId: string; toggleKey: string; body: Pick<CreateToggleBody, 'targets'> }
    >({
      query: ({ organizationId, projectId, toggleKey, body }) => ({
        url: `api/organizations/${organizationId}/projects/${projectId}/toggles/${toggleKey}`,
        method: 'PATCH',
        body: cleanRequest(body),
      }),
      invalidatesTags: (_result, _error, { toggleKey, projectId }) => [
        { type: 'Toggles', id: `${projectId}-${toggleKey}` },
      ],
    }),
  }),
});

export const { useCreateToggleMutation, useGetToggleQuery, useGetTogglesQuery, useUpdateToggleTargetsMutation } =
  toggleApi;
