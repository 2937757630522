import { Box, Card } from '@hyphen/hyphen-components';
import { Toggle } from '../../types/toggle';
import ClickToCopy from '../common/ClickToCopy';
import Targets from './Targets';
import { Project } from '../../services/projects';
import LastEvent from './LastEvent';
interface ToggleStatusProps {
  project: Project;
  toggle: Toggle;
}

export const ToggleStatus = ({ project, toggle }: ToggleStatusProps) => {
  return (
    <Box gap="4xl">
      <Card>
        <Box direction={{ base: 'column', tablet: 'row' }} fontSize="sm">
          <Box
            padding={{ base: 'lg', tablet: 'xl', desktop: '2xl' }}
            borderColor="subtle"
            borderWidth={{ base: '0 0 sm 0', tablet: '0 sm 0 0' }}
            alignItems={{ base: 'center', tablet: 'flex-start' }}
            direction={{ base: 'row', tablet: 'column' }}
            gap="lg"
            minWidth="7xl"
          >
            <Box fontWeight="semibold" color="secondary" fontSize="xs">
              Key ID
            </Box>
            <ClickToCopy fontSize="xs" text={toggle.key} />
          </Box>
          <Box
            flex="auto"
            padding={{ base: 'lg', tablet: 'xl', desktop: '2xl' }}
            alignItems={{ base: 'center', desktop: 'flex-start' }}
            direction={{ base: 'row', desktop: 'column' }}
            gap="lg"
          >
            <LastEvent toggleKey={toggle.key} projectId={project.id} />
          </Box>
        </Box>
      </Card>

      <Targets toggle={toggle} />
    </Box>
  );
};
