import {
  Box,
  Button,
  Icon,
  toast,
  FormikTextInput,
  FormikSelectInputNative,
  FormikRadioGroup,
  FormikTextareaInput,
  Alert,
} from '@hyphen/hyphen-components';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { InferType } from 'yup';
import { useNavigate } from 'react-router-dom';
import { Project } from '../../services/projects';
import { Link } from 'react-router-dom';
import { forbiddenWords } from '../../utils/validations';
import { useCallback, useEffect, useState } from 'react';
import { useCreateToggleMutation } from '../../services/toggle';
import { ApiError } from '../ApiError';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';
import { EntityNames } from '@hyphen/nucleus/dist/types';

enum ToggleType {
  Boolean = 'boolean',
  String = 'string',
  Number = 'number',
  Object = 'object',
}

const ToggleTypeOptions = [
  { id: ToggleType.Boolean, label: 'Boolean', value: ToggleType.Boolean },
  { id: ToggleType.String, label: 'String', value: ToggleType.String },
  { id: ToggleType.Number, label: 'Number', value: ToggleType.Number },
  { id: ToggleType.Object, label: 'JSON Object', value: ToggleType.Object },
];

const createToggleSchema = yup.object().shape({
  keyId: yup
    .string()
    .matches(/^[a-zA-Z0-9-_]+$/, 'Invalid Key ID')
    .notOneOf(forbiddenWords, `Cannot contain the following words: ${forbiddenWords.join(', ')}`)
    .required('Key ID is required'),
  toggleType: yup.mixed<ToggleType>().oneOf(Object.values(ToggleType)).required('Type is required'),
  defaultValue: yup
    .mixed()
    .required('Default return value is required')
    .test('validate-default-value', 'Invalid default return value', function (value) {
      const { toggleType } = this.parent;

      if (toggleType === ToggleType.Boolean) {
        return typeof value === 'boolean';
      } else if (toggleType === ToggleType.String) {
        return typeof value === 'string';
      } else if (toggleType === ToggleType.Number) {
        return typeof value === 'number' && !isNaN(value);
      } else if (toggleType === ToggleType.Object) {
        if (typeof value !== 'string') return false;
        try {
          JSON.parse(value);
          return true;
        } catch {
          return false;
        }
      }
      return false; // Fallback for unknown types
    }),
  description: yup.string(),
  projectId: yup.string().required('Select a project'),
});

type CreateToggleSchema = InferType<typeof createToggleSchema>;

interface CreateToggleFormProps {
  organizationId: string;
  selectedProject?: Project;
  projectOptions?: { label: string; value: string }[];
}

const CreateToggleForm: React.FC<CreateToggleFormProps> = ({ organizationId, selectedProject, projectOptions }) => {
  const [createToggle, { error }] = useCreateToggleMutation();
  const navigate = useNavigate();
  const ability = useOrganizationAbilityContext();

  const initialValues: CreateToggleSchema = {
    keyId: '',
    description: '',
    toggleType: ToggleType.Boolean,
    projectId: selectedProject?.alternateId || '',
    defaultValue: false,
  };

  const [canUpdateProject, setCanUpdateProject] = useState<boolean>(false);

  useEffect(() => {
    if (selectedProject) {
      setCanUpdateProject(ability.can('update', selectedProject) && ability.can('create', EntityNames.Toggle));
    } else {
      setCanUpdateProject(ability.can('create', EntityNames.Toggle));
    }
  }, [selectedProject, ability]);

  const handleOnSubmit = useCallback(
    async (values: CreateToggleSchema) => {
      const { error, data: responseData } = await createToggle({
        organizationId,
        projectId: values.projectId,
        key: values.keyId,
        description: values.description,
        type: values.toggleType,
        defaultValue: values.defaultValue,
      });

      if (!error && responseData) {
        navigate(`/${organizationId}/${values.projectId}/toggles/${responseData.key}`);
        toast.success('Flag created', { duration: 5000 });
      } else if (error) {
        toast.error('Create toggle failed');
      }
    },
    [createToggle, organizationId, navigate],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={createToggleSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleOnSubmit}
    >
      {({ isSubmitting, errors, values, setFieldValue }) => (
        <Form noValidate>
          <Box gap="4xl">
            {selectedProject ? (
              <Box gap="xs">
                <Box fontSize="sm">Project</Box>
                <Box>
                  <Link to={`/${organizationId}/${selectedProject.alternateId}/toggle`}>{selectedProject.name}</Link>
                </Box>
              </Box>
            ) : (
              <Box gap="lg">
                <Field
                  component={FormikSelectInputNative}
                  id="projectId"
                  name="projectId"
                  label="For Project"
                  placeholder="Select project..."
                  options={projectOptions}
                  value={values.projectId}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    setFieldValue('projectId', event.target.value);
                    setCanUpdateProject(ability.can('update', { id: event.target.value }));
                  }}
                  disabled={isSubmitting}
                  error={errors.projectId}
                />
              </Box>
            )}

            {canUpdateProject ? (
              <>
                <Field
                  component={FormikRadioGroup}
                  id="toggleType"
                  name="toggleType"
                  title="Type"
                  placeholder="Select type..."
                  options={ToggleTypeOptions}
                  value={values.toggleType}
                  description="A feature toggle evaluation returns a value of this type"
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    setFieldValue('toggleType', event.target.value);
                    setFieldValue('defaultValue', undefined);
                  }}
                  disabled={isSubmitting}
                  error={errors.toggleType}
                />

                <Box gap="sm">
                  <Field
                    component={FormikTextInput}
                    id="keyId"
                    name="keyId"
                    label="Key ID"
                    placeholder="e.g. 'beta-dashboard'"
                    helpText="No spaces, only alphanumeric characters, hyphens, and underscores"
                    disabled={isSubmitting}
                    errors={errors.keyId}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue('keyId', event.target.value.replace(/\s+/g, '-'))
                    }
                  />
                  <Box direction="row" gap="sm" fontSize="sm" alignItems="flex-start">
                    <Box width="md">
                      <Icon name="c-warning" color="info" />
                    </Box>
                    Use this key to reference the feature toggle in your applications. Once created, keys are
                    permanent and cannot be modified.
                  </Box>
                </Box>

                <DefaultValueField
                  toggleType={values.toggleType}
                  defaultValue={values.defaultValue}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  isSubmitting={isSubmitting}
                />

                <Field
                  component={FormikTextInput}
                  id="description"
                  name="description"
                  label="Description (optional)"
                  placeholder="e.g. 'Beta dashboard is visible to end users'"
                  disabled={isSubmitting}
                  errors={errors.description}
                />

                {error && <ApiError error={error} />}
                <Box direction="row" gap="sm">
                  <Button type="submit" variant="primary" isLoading={isSubmitting}>
                    Create Feature Toggle
                  </Button>
                </Box>
              </>
            ) : (
              <Alert
                variant="danger"
                title="Permission Denied"
                hasIcon
                message="You do not have permission to create or update toggles for this project. Select a different project or contact a project owner to grant you access."
                maxWidth="9xl"
              />
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default CreateToggleForm;

interface DefaultValueFieldProps {
  toggleType: ToggleType;
  defaultValue: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  errors: any;
  isSubmitting: boolean;
}

const DefaultValueField: React.FC<DefaultValueFieldProps> = ({
  toggleType,
  defaultValue,
  setFieldValue,
  errors,
  isSubmitting,
}) => {
  const commonProps = {
    id: 'defaultValue',
    name: 'defaultValue',
    value: defaultValue,
    disabled: isSubmitting,
    error: errors.defaultValue,
  };

  switch (toggleType) {
    case ToggleType.Boolean:
      return (
        <Field
          {...commonProps}
          component={FormikRadioGroup}
          title="Default Return Value"
          description="Boolean value returned when no matching target context(s) are found"
          options={[
            { id: 'true', label: 'True', value: true },
            { id: 'false', label: 'False', value: false },
          ]}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue('defaultValue', event.target.value === 'true')
          }
        />
      );
    case ToggleType.Object:
      return (
        <Field
          {...commonProps}
          component={FormikTextareaInput}
          label="Default Return Value"
          helpText="Object returned when no matching target context(s) are found"
          placeholder="Enter a JSON object"
          rows={6}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue('defaultValue', event.target.value)
          }
        />
      );
    case ToggleType.Number:
      return (
        <Field
          {...commonProps}
          component={FormikTextInput}
          label="Default Return Value"
          helpText="Number returned when no matching target context(s) are found"
          placeholder="Enter number"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue('defaultValue', Number(event.target.value))
          }
        />
      );
    case ToggleType.String:
    default:
      return (
        <Field
          {...commonProps}
          component={FormikTextInput}
          label="Default Return Value"
          helpText="String returned when no matching target context(s) are found"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue('defaultValue', event.target.value)
          }
        />
      );
  }
};
