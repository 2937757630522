import { ConnectionsList } from '../integrations/connections/ConnectionsList';
import { Organization } from '../../services/organization';
import { Helmet } from 'react-helmet';
import { Project } from '../../services/projects';

interface ProjectConnectionsProps {
  project: Project;
  organization: Organization;
}

export default function ProjectConnections({ project, organization }: ProjectConnectionsProps) {
  return (
    <>
      <Helmet title={`${project.name} Integration Connections`} />
      <ConnectionsList
        organizationId={organization.id}
        filter={{
          entity: {
            type: 'Project',
            id: project.id,
            name: project.name,
          },
          entityType: 'Project',
        }}
      />
    </>
  );
}
