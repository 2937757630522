import { ConnectionsList } from '../integrations/connections/ConnectionsList';
import { Organization } from '../../services/organization';
import { Helmet } from 'react-helmet';

import { Environment } from '../../services/environments';

interface EnvironmentConnectionsProps {
  environment: Environment;
  organization: Organization;
}

export default function EnvironmentConnections({ environment, organization }: EnvironmentConnectionsProps) {
  return (
    <>
      <Helmet title={`${environment.name} Integration Connections`} />
      <ConnectionsList
        organizationId={organization.id}
        filter={{
          entity: {
            type: 'ProjectEnvironment',
            id: environment.id,
            name: environment.name,
          },
          entityType: 'ProjectEnvironment',
        }}
      />
    </>
  );
}
