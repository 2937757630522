import { Box, Card, Heading, Spinner } from '@hyphen/hyphen-components';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useOrganizationsList } from '../providers/OrganizationsListProvider';
import { Helmet } from 'react-helmet';
import { OrganizationAvatar } from '../components/organization/OrganizationAvatar';
import { routes } from '..';
import { Organization } from '../services/organization';

export const OrganizationList = () => {
  const navigate = useNavigate();
  const { organizations, isLoading: areOrganizationsLoading } = useOrganizationsList();

  useEffect(() => {
    if (!areOrganizationsLoading) {
      if (organizations?.length === 0) {
        navigate(`/${routes.getStarted}`);
      } else if (organizations?.length === 1) {
        navigate(`/${organizations[0].id}`);
      }
    }
  }, [areOrganizationsLoading, navigate, organizations]);
  return (
    <>
      <Helmet>
        <title>Organizations</title>
      </Helmet>
      <Box
        margin={{ base: 'lg auto 5xl', desktop: '2xl auto 5xl' }}
        width={{ base: '100', desktop: '90' }}
        gap="xl"
        padding={{ base: '3xl', tablet: '4xl', desktop: '5xl' }}
      >
        <Box childGap="sm">
          <Heading as="h1" size={{ base: 'md', tablet: 'lg', desktop: 'xl' }}>
            Organizations
          </Heading>
        </Box>
        {areOrganizationsLoading ? (
          <Spinner />
        ) : (
          <Card>
            {organizations?.map((org) => (
              <OrganizationItem key={org.id} org={org} />
            ))}
          </Card>
        )}
      </Box>
    </>
  );
};

const OrganizationItem = ({ org }: { org: Organization }) => (
  <Box
    childGap="sm"
    padding="xl"
    direction="row"
    alignItems="center"
    borderColor="default"
    borderWidth="sm 0 0 0"
    className="row-item"
  >
    <Box flex="auto" alignItems="center" direction="row" gap="lg">
      <OrganizationAvatar name={org?.name || 'Unknown Organization'} logoUrl={org.logoUrl} />
      <Link to={`/${org.id}`}>
        <Box as="span" display="inline" fontWeight="bold">
          {org.name}
        </Box>
      </Link>
    </Box>
  </Box>
);
