import { Box, Card, Heading, Icon } from '@hyphen/hyphen-components';

export const EmptyToggleList = () => {
  return (
    <Card minHeight="8xl" background="brand-gradient-cyan" color="white">
      <Box
        width="100"
        height="100"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        gap="2xl"
        padding="5xl"
      >
        <Icon name="logo-toggle" size="3xl" />

        <Box gap="md" alignItems="center">
          <Heading size={{ base: 'sm', tablet: 'md', desktop: 'lg' }} as="h2">
            No toggles exist
          </Heading>
          <Box
            as="p"
            maxWidth="380px"
            fontSize={{ base: 'sm', desktop: 'md' }}
            color="white"
            style={{ opacity: '.9' }}
          >
            Feature toggles allow live application behavior to be altered without deploying code
          </Box>
        </Box>
      </Box>
    </Card>
  );
};
