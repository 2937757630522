type LogicCondition = {
  '=='?: [{ var: string }, string];
  [key: string]: any;
};

type Logic = {
  and?: LogicCondition[];
};

type ParsedCondition = {
  key: string;
  operator: string;
  value: string;
};
export const parseJsonLogic = (logic: string): ParsedCondition[] => {
  let parsedLogic: Logic;

  try {
    parsedLogic = JSON.parse(logic);
  } catch (error) {
    throw new Error('Invalid JSON logic format. Please check the target syntax.');
  }

  // Extract conditions from the "and" array
  const conditions = parsedLogic.and || [];

  return conditions.map((condition: LogicCondition) => {
    // Extract the first operator and operands
    const operator = Object.keys(condition)[0];
    const operands = condition[operator];

    // Handle cases where the structure doesn't match expectations
    if (!operands || operands.length !== 2 || typeof operands[0] !== 'object') {
      throw new Error('Invalid logic format');
    }

    return {
      key: operands[0].var, // Extract the variable name
      operator, // Operator, e.g., "=="
      value: operands[1], // The comparison value
    };
  });
};
