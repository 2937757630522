import { Box, Modal } from '@hyphen/hyphen-components';
import { ModalProps } from '../../../components/types/modal';
import { Connection } from '../../../types/connections';
import { useRef } from 'react';

interface ConnectionDetailsModalProps extends ModalProps {
  connection: Connection;
  title?: string;
}
export const ConnectionDetailsModal = ({
  isOpen,
  onClose,
  connection,
  title = 'Connection Details',
}: ConnectionDetailsModalProps) => {
  const modalRef = useRef<HTMLFormElement>(null);
  return (
    <Modal ariaLabelledBy="connectionDetailsModal" maxWidth="9xl" isOpen={isOpen} onDismiss={onClose} ref={modalRef}>
      {title && <Modal.Header id="connectionDetailsModalTitle" title={title} onDismiss={onClose} />}
      <Modal.Body gap="2xl">
        <Box className="lh-text" childGap="sm" display="block">
          {Object.keys(connection.config).map((key) => (
            <Box key={key} display="block">
              <Box display="block" fontWeight="bold">
                {key}
              </Box>
              <Box display="block" fontSize="sm">
                {connection.config[key]}
              </Box>
            </Box>
          ))}
          {connection.errors && (
            <Box display="block">
              <Box display="block" fontWeight="bold">
                Errors
              </Box>
              {connection.errors.map((error, index) => (
                <Box key={index} display="block" fontSize="sm">
                  {error}
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Modal.Body>
    </Modal>
  );
};
