import { ConnectionsList } from '../integrations/connections/ConnectionsList';
import { Organization } from '../../services/organization';
import { Helmet } from 'react-helmet';
import { App } from '../../services/apps';

interface AppConnectionsProps {
  app: App;
  organization: Organization;
}

export default function AppConnections({ app, organization }: AppConnectionsProps) {
  return (
    <>
      <Helmet title={`${app.name} Integration Connections`} />
      <ConnectionsList
        organizationId={organization.id}
        filter={{
          entity: {
            type: 'App',
            id: app.id,
            name: app.name,
          },
          entityType: 'App',
        }}
      />
    </>
  );
}
