import { IntegrationSetupForm } from '../common/IntegrationSetupForm';
import { NpmSetupFormConfig, IntegrationType } from '../../../../types/integrations';
import * as yup from 'yup';

interface NpmSetupFormProps {
  organizationId: string;
}

const npmSetupFormSchema = yup.object().shape({
  organizationName: yup.string().required('Required'),
  token: yup.string().required('Required'),
});

const npmFormConfig = [
  { id: 'organizationName', type: 'text', label: 'NPM Organization Name', initialValue: '' },
  { id: 'token', type: 'password', label: 'NPM Access Token', initialValue: '' },
];

export const NpmSetupForm = ({ organizationId }: NpmSetupFormProps) => (
  <IntegrationSetupForm
    organizationId={organizationId}
    validationSchema={npmSetupFormSchema}
    formConfig={npmFormConfig}
    configMapper={(values: NpmSetupFormConfig) => ({
      organizationName: values.organizationName,
      token: values.token,
    })}
    integrationType={IntegrationType.npm}
  />
);
