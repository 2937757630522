import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Icon,
  Collapsible,
  CollapsibleTrigger,
  CollapsibleContent,
  useOpenClose,
  Button,
} from '@hyphen/hyphen-components';
import { useGetEventsQuery } from '../../services/events';
import { EventTypeLabel } from '@hyphen/nucleus/dist/types';
import DateTimeDisplay from '../DateTime';
import { timeAgo } from '../../utils/dateUtils';
import { Organization } from '../../services/organization';
import { useOrganization } from '../../providers/OrganizationProvider';
import { EventReferenceType, EventType } from '../../types/events';

interface LastEventProps {
  toggleKey: string;
  projectId: string;
}

const LastEvent: React.FC<LastEventProps> = ({ toggleKey, projectId }) => {
  const { organization = {} as Organization } = useOrganization();
  const { isOpen, handleToggle } = useOpenClose();
  const [retryAttempted, setRetryAttempted] = useState(false);

  const filters = useMemo(
    () => ({
      pageSize: 1,
      types: [EventType.CreateToggle, EventType.UpdateToggle, EventType.DeleteToggle],
      references: [
        {
          id: toggleKey,
          type: EventReferenceType.Toggle,
        },
        {
          id: projectId,
          type: EventReferenceType.Project,
        },
      ],
      referenceOperation: 'AND',
    }),
    [toggleKey, projectId],
  );

  const { data, isLoading, error, refetch } = useGetEventsQuery({
    orgId: organization?.id || '',
    body: filters,
  });

  const lastEvent = data?.data[0];

  const handleViewRawEventData = () => {
    if (lastEvent) {
      const jsonStr = JSON.stringify(lastEvent, null, 2);
      const newWindow = window.open();
      if (newWindow) {
        newWindow.document.write(`<pre>${jsonStr}</pre>`);
        newWindow.document.close();
      }
    }
  };

  // For new creations, sometimes the page loads before a create event has been written to the database
  useEffect(() => {
    if (data?.data.length === 0 && !isLoading && !error && !retryAttempted) {
      const timer = setTimeout(() => {
        setRetryAttempted(true);
        refetch();
      }, 1000); // Retry after 1 second

      return () => clearTimeout(timer);
    }
  }, [data, isLoading, error, refetch, retryAttempted]);

  if (isLoading) {
    return (
      <Box width="100" gap="md">
        <Box radius="sm" height="14px" background="secondary" width="120px" />
        <Box radius="sm" height="16px" background="secondary" width="200px" />
        <Box radius="sm" height="14px" background="secondary" width="72px" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box width="100" gap="md">
        Unable to fetch last event
      </Box>
    );
  }

  return (
    <Box width="100" gap="md">
      <Box direction="row" alignItems="center" gap="md" fontFamily="body" fontSize="xs" color="secondary">
        <Box borderWidth="0 sm 0 0" borderColor="default" padding="0 md 0 0">
          {timeAgo(lastEvent?.timestamp || '')}
        </Box>

        <DateTimeDisplay value={lastEvent?.timestamp || ''} placement="top" />
      </Box>
      <Box display="block">
        {lastEvent && (
          <>
            {lastEvent.member.name}
            {` `}
            <span className="transform-lowercase">
              {EventTypeLabel[lastEvent.type as keyof typeof EventTypeLabel]}
            </span>
          </>
        )}
      </Box>
      <Collapsible open={isOpen} onOpenChange={handleToggle}>
        <CollapsibleTrigger asChild>
          <Box
            as="button"
            flex="auto"
            borderWidth="0"
            background="transparent"
            padding="0"
            color="secondary"
            cursor="pointer"
            hover={{ color: 'base' }}
            className="text-underline"
            fontSize="xs"
            display="flex"
            direction="row"
            gap="2xs"
          >
            {isOpen ? 'collapse' : 'expand'}
            <Icon name={isOpen ? 'caret-sm-up' : 'caret-sm-down'} />
          </Box>
        </CollapsibleTrigger>
        <CollapsibleContent className="w-100 font-size-xs lh-base">
          <Box as="code" alignItems="flex-start" gap="lg" padding="lg" margin="lg 0 0 0" width="100" overflow="auto">
            <Button size="sm" variant="secondary" onClick={handleViewRawEventData}>
              View Raw Event Data
            </Button>
            <pre>{JSON.stringify(lastEvent?.data, null, 2)}</pre>
          </Box>
        </CollapsibleContent>
      </Collapsible>
    </Box>
  );
};

export default LastEvent;
