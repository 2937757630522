import EditTeamForm from '../../components/teams/EditTeamForm';
import DeleteTeam from '../../components/teams/DeleteTeam';
import { TeamDetails } from '../../types/teams';
import { Organization } from '../../services/organization';
import { useOrganization } from '../../providers/OrganizationProvider';
import { Helmet } from 'react-helmet';

export default function TeamSettings({ team }: { team: TeamDetails }) {
  const { organization = {} as Organization } = useOrganization();
  return (
    <>
      <Helmet title={`${team.name} Settings`} />
      <EditTeamForm data={team} organizationId={organization.id} />
      <DeleteTeam team={team} organizationId={organization.id} />
    </>
  );
}
