import { Box, Button } from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { Guide } from '../../../guide/Guide';
import { GuideStep } from '../../../guide/GuideStep';
import { IncidentIoSetupForm } from './IncidentIoSetupForm';

interface IncidentIoSetupGuideProps {
  organizationId: string;
}

export const IncidentIoSetupGuide = ({ organizationId }: IncidentIoSetupGuideProps) => {
  return (
    <Guide title="Incident.io Setup Guide">
      <GuideStep title="Sign in or create your incident.io account">
        <p>
          <Button asChild iconSuffix="launch-app">
            <Link to="https://app.incident.io/login" target="_blank" rel="noreferrer">
              Sign in to incident.io
            </Link>
          </Button>
        </p>
        <p>or</p>
        <p>
          <Button asChild iconSuffix="launch-app">
            <Link to="https://incident.io/" target="_blank" rel="noreferrer">
              Create an incident.io account
            </Link>
          </Button>
        </p>
      </GuideStep>
      <GuideStep title="Generate an API key">
        <Box as="ol" childGap="2xl" padding="0 0 0 2xl">
          <li>
            <p>
              In the incident.io dashboard, go to <strong>Settings</strong> &gt; <strong>API keys</strong>.
            </p>
            <p>
              <em>You need admin permissions to access this section.</em>
            </p>
          </li>
          <li>
            Click <strong>+ Add new</strong> and provide a name for the API key (e.g., "Hyphen Integration").
          </li>
          <li>
          Select the following permissions for the API key: catalog_editor and catalog_viewer. These permissions ensure the API key has the necessary access to integrate with catalog teams and catalog services.
          </li>
          <li>
            Once created, you will see the generated API key. Copy the API key and save it securely, as it won't be shown
            again.
          </li>
        </Box>
      </GuideStep>
      <GuideStep title="Connect incident.io">
        <p>Enter the API key you generated to connect incident.io to Hyphen.</p>
        <IncidentIoSetupForm organizationId={organizationId} />
      </GuideStep>
    </Guide>
  );
};
