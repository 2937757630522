import { NpmIntegration } from '../../../types/integrations';
import { IntegrationCard } from './common/IntegrationCard';

interface NpmDetailsProps {
  integration: NpmIntegration;
}

export const NpmDetails = ({ integration }: NpmDetailsProps) => {
  return (
    <IntegrationCard
      status="Connected"
      details={[
        { label: 'Organization Name', value: integration.config.organizationName },
        { label: 'Organization ID', value: integration.config.organizationId },
      ]}
      integration={integration}
    />
  );
};

export default NpmDetails;
