import { Box } from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { useOrganization } from '../../providers/OrganizationProvider';
import { Toggle, ToggleReturnType } from '../../types/toggle';
import { Project } from '../../services/projects';
// import { ColorDot } from '../common/ColorDot';

const ToggleListItem = ({ toggle, project }: { toggle: Toggle; project?: Project }) => {
  const { organization } = useOrganization();

  const { key, description, tags, type } = toggle;

  return (
    <Box
      className="row-item"
      direction={{ base: 'column', desktop: 'row' }}
      borderColor="default"
      borderWidth="sm 0 0 0"
      padding="xl 3xl"
      gap="2xl"
      alignItems={{ base: 'flex-start', desktop: 'center' }}
    >
      <ToggleDetails
        keyName={key}
        description={description}
        returnType={type}
        tags={tags}
        projectId={project?.alternateId}
        organizationId={organization?.id}
      />
    </Box>
  );
};

const ToggleDetails = ({
  keyName,
  returnType,
  projectId,
  organizationId,
}: {
  description?: string;
  keyName: string;
  tags: string[] | undefined;
  returnType: ToggleReturnType;
  projectId?: string;
  organizationId: string | undefined;
}) => (
  <Box
    width="100"
    fontSize="sm"
    alignItems="flex-start"
    justifyContent={{ base: 'space-between', tablet: 'flex-start' }}
    gap="sm"
    direction="row"
  >
    <Link to={`/${organizationId}/${projectId}/toggles/${keyName}`} className="font-weight-semibold">
      {keyName}
    </Link>
    <Box
      margin="0 0 0 sm"
      as="span"
      fontFamily="monospace"
      fontSize="xs"
      display="inline-block"
      padding="xs"
      color="secondary"
      background="secondary"
    >
      {returnType}
    </Box>
  </Box>
);

export default ToggleListItem;
