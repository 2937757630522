import { Guide } from '../../../guide/Guide';
import { GuideStep } from '../../../guide/GuideStep';
import { Button } from '@hyphen/hyphen-components';

interface GithubSetupGuideProps {
  organizationId: string;
}

export const GithubSetupGuide = ({ organizationId }: GithubSetupGuideProps) => {
  return (
    <Guide title="Setup Guide">
      <GuideStep title="Add Hyphen to your Github organization">
        <Button asChild variant="primary" iconSuffix="launch-app">
          <a target="_blank" href="https://github.com/apps/hyphen-development" rel="noreferrer">
            Install the App
          </a>
        </Button>
      </GuideStep>
    </Guide>
  );
};
