import { ConnectionType } from './connections';
import { EntityType } from './executionContext';

export enum IntegrationType {
  googleWorkspace = 'googleWorkspace',
  github = 'github',
  googleCloud = 'googleCloud',
  azure = 'azure',
  aws = 'aws',
  slack = 'slack',
  office365 = 'office365',
  incidentIo = 'incidentIo',
  npm = 'npm',
}

export interface AwsIntegrationConfig {
  region: string;
  managementAccountId: string;
  name: string;
}

export interface AwsIntegrationOptions extends Record<string, any> {}

export interface AzureIntegrationConfig {
  azureTenantId: string;
  name: string;
}

export interface AzureIntegrationOptions extends Record<string, any> {}

export interface GoogleWorkspaceIntegrationConfig {
  domain: string;
  adminUserEmail: string;
}

export interface GoogleWorkspaceIntegrationOptions extends Record<string, any> {}

export interface githubIntegrationConfig {
  installationId: string;
  githubOrgId: string;
  githubOrgName: string;
}

export interface GithubIntegrationOptions extends Record<string, any> {}

export interface GoogleCloudIntegrationConfig {
  googleCloudOrganizationId: string;
  googleCloudOrganizationName: string;
}

export interface GoogleCloudIntegrationOptions extends Record<string, any> {}

export interface SlackIntegrationConfig {
  workspaceId: string;
  workspaceName: string;
}

export interface SlackIntegrationOptions extends Record<string, any> {}

export interface Office365IntegrationConfig {
  azureTenantId: string;
  name: string;
}

export interface Office365IntegrationOptions extends Record<string, any> {}

export interface IncidentIoIntegrationConfig {
  organizationName: string;
  dashboardUrl: string;
  token: string;
  catalogServiceTypeId: string;
  catalogTeamType: {
    id: string;
    memberAttributeId: string;
  };
  catalogUserTypeId: string;
}

export interface IncidentIoIntegrationOptions extends Record<string, any> {}

export interface BaseIntegration {
  id: string;
  type: IntegrationType;
  config: unknown;
  options: unknown;
  provides: [
    {
      connectionType: ConnectionType;
      for: EntityType[];
    },
  ];
}

export interface AzureIntegration extends BaseIntegration {
  type: IntegrationType.azure;
  config: AzureIntegrationConfig;
  options: AzureIntegrationOptions;
}

export interface AwsIntegration extends BaseIntegration {
  type: IntegrationType.aws;
  config: AwsIntegrationConfig;
  options: AwsIntegrationOptions;
}

export interface GoogleWorkspaceIntegration extends BaseIntegration {
  type: IntegrationType.googleWorkspace;
  config: GoogleWorkspaceIntegrationConfig;
  options: GoogleWorkspaceIntegrationOptions;
}

export interface GithubIntegration extends BaseIntegration {
  type: IntegrationType.github;
  config: githubIntegrationConfig;
  options: GithubIntegrationOptions;
}

export interface GoogleCloudIntegration extends BaseIntegration {
  type: IntegrationType.googleCloud;
  config: GoogleCloudIntegrationConfig;
  options: GoogleCloudIntegrationOptions;
}

export interface SlackIntegrationInvite {
  type: IntegrationType.slack;
  url: string;
}

export interface SlackIntegration extends BaseIntegration {
  type: IntegrationType.slack;
  config: SlackIntegrationConfig;
  options: SlackIntegrationOptions;
}

export interface Office365Integration extends BaseIntegration {
  type: IntegrationType.office365;
  config: Office365IntegrationConfig;
  options: Office365IntegrationOptions;
}

export interface IncidentIoIntegration extends BaseIntegration {
  type: IntegrationType.incidentIo;
  config: IncidentIoIntegrationConfig;
  options: IncidentIoIntegrationOptions;
}

export type Integration =
  | GoogleWorkspaceIntegration
  | GithubIntegration
  | GoogleCloudIntegration
  | AzureIntegration
  | AwsIntegration
  | SlackIntegration
  | Office365Integration
  | IncidentIoIntegration
  | NpmIntegration;

export function isGoogleWorkspaceIntegration(integration: Integration): integration is GoogleWorkspaceIntegration {
  return integration.type === IntegrationType.googleWorkspace;
}

export function isGithubIntegration(integration: Integration): integration is GithubIntegration {
  return integration.type === IntegrationType.github;
}

export function isGoogleCloudIntegration(integration: Integration): integration is GoogleCloudIntegration {
  return integration.type === IntegrationType.googleCloud;
}

export enum ProductivitySuite {
  googleWorkspace = IntegrationType.googleWorkspace,
  office365 = IntegrationType.office365,
}

export enum CloudProvider {
  aws = IntegrationType.aws,
  azure = IntegrationType.azure,
  googleCloud = IntegrationType.googleCloud,
}

export type IntegrationSetupKeys = 'productivitySuite' | 'cloudProvider' | 'github' | 'slack' | 'npm';
export type IntegrationSetup = Record<IntegrationSetupKeys, boolean>;

export interface NpmIntegrationConfig {
  organizationName: string;
  organizationId: string;
}

export interface NpmSetupFormConfig {
  organizationName: string;
  token: string;
}

export interface NpmIntegrationOptions extends Record<string, any> {}

export interface NpmIntegration extends BaseIntegration {
  type: IntegrationType.npm;
  config: NpmIntegrationConfig;
  options: NpmIntegrationOptions;
}

export function isNpmIntegration(integration: Integration): integration is NpmIntegration {
  return integration.type === IntegrationType.npm;
}
