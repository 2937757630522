import { IntegrationSetupForm } from '../common/IntegrationSetupForm';
import { Office365IntegrationConfig, IntegrationType } from '../../../../types/integrations';
import * as yup from 'yup';

interface Office365SetupFormProps {
  organizationId: string;
}

const office365SetupFormSchema = yup.object().shape({
  azureTenantId: yup.string().required('Required'),
});

const office365FormConfig = [{ id: 'azureTenantId', type: 'text', label: 'Azure Tenant ID', initialValue: '' }];

export const Office365SetupForm = ({ organizationId }: Office365SetupFormProps) => (
  <IntegrationSetupForm
    organizationId={organizationId}
    validationSchema={office365SetupFormSchema}
    formConfig={office365FormConfig}
    configMapper={(values: Office365IntegrationConfig) => ({
      azureTenantId: values.azureTenantId,
    })}
    integrationType={IntegrationType.office365}
  />
);
