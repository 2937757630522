import React, { useMemo } from 'react';
import { Box, Card, Icon } from '@hyphen/hyphen-components';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

// TODO update these types
interface DraggableTargetProps {
  targetContext: any;
  returnType: any;
}

export const DraggableTarget = React.memo(({ targetContext, returnType }: DraggableTargetProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging, isSorting, newIndex } = useSortable({
    id: targetContext.id,
  });

  const style = useMemo(
    () => ({
      transform: CSS.Translate.toString(transform),
      transition,
      cursor: 'move',
      touchAction: 'none',
    }),
    [transform, transition],
  );

  const DisplayIndex = isDragging || isSorting ? newIndex + 1 : <Icon name="grab" size="2xl" />;

  return (
    <Box
      ref={setNodeRef}
      style={style}
      as="li"
      direction="row"
      alignItems="center"
      gap="lg"
      {...listeners}
      {...attributes}
    >
      <Box flex="auto" id={targetContext.id} direction="row" alignItems="center" gap="lg">
        <Box width="4xl">{DisplayIndex}</Box>
        <TargetCard targetContext={targetContext} returnType={returnType} />
      </Box>
      {isDragging || isSorting ? (
        <Box width="45px" height="sm" />
      ) : (
        <Box width="45px" height="sm" />
        /* TODO <DrawerProvider>
            <EditTargetContextDrawer targetContext={targetContext} returnType={returnType} />
          </DrawerProvider> */
      )}
    </Box>
  );
});

const TargetCard: React.FC<{ targetContext: any; returnType: any }> = React.memo(
  ({ targetContext: { parsedLogic, value }, returnType }) => (
    <Card hover={{ borderColor: 'info', shadow: 'sm' }}>
      <Box
        direction={{ base: 'column', tablet: 'row' }}
        gap={{ base: 'sm', tablet: 'xl' }}
        alignItems="stretch"
        padding={{ base: 'lg', tablet: 'xl' }}
        fontFamily="monospace"
        fontSize={{ base: 'xs', tablet: 'sm' }}
      >
        <ContextList contexts={parsedLogic} />

        <Box
          display="inline-block"
          radius="sm"
          background="tertiary"
          padding="sm"
          alignSelf={{ base: 'flex-start', tablet: 'center' }}
        >
          RETURNS
        </Box>

        <Box
          radius="sm"
          maxWidth="40"
          minWidth="6xl"
          borderColor="subtle"
          borderWidth="sm"
          padding="sm lg"
          justifyContent="center"
          alignItems="flex-end"
          style={{ wordBreak: 'break-word' }}
          color={returnType === 'boolean' ? (value ? 'success' : 'danger') : 'base'}
        >
          {String(value)}
        </Box>
      </Box>
    </Card>
  ),
);

const ContextList: React.FC<{ contexts: any[] }> = React.memo(({ contexts }) => (
  <Box as="ol" flex="auto" width="100" padding="0" borderColor="subtle" borderWidth="sm" radius="sm">
    {contexts.map(({ key, operator, value }, index) => (
      <ContextListItem key={index} contextKey={key} operator={operator} value={value} />
    ))}
  </Box>
));

const ContextListItem: React.FC<any> = React.memo(({ contextKey, operator, value }) => (
  <Box
    as="li"
    direction="row"
    gap="sm"
    alignItems="center"
    padding="sm md"
    className="row-item"
    borderColor="subtle"
    borderWidth="sm 0 0 0"
  >
    <Box style={{ flex: '1 0' }}>{contextKey}</Box>
    <Box width="5xl" textAlign="center">
      {operator}
    </Box>
    <Box style={{ flex: '1 0' }}>{value}</Box>
  </Box>
));
