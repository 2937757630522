import TagManager from '@sooro-io/react-gtm-module';
import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import AuthPage from './authPage';

export interface User {
  given_name: string;
  family_name: string;
  name: string;
  email: string;
  userId: string;
}

export interface IAuthContext {
  login: (state?: string, impersonateOrg?: string) => Promise<void>;
  logout: () => Promise<void>;
  register?: (state?: string) => Promise<void>;
  user?: User;
  isLoading: boolean;
  isAuthenticated: boolean;
  refreshToken: () => Promise<void>;
  claims?: Record<string, any>;
}

export type RedirectSuccess = (state: string) => void;
export type RedirectFail = (error: any) => void;

export interface AuthConfig extends PropsWithChildren {
  clientId?: string;
  scope?: string;
}

export const AuthContext = React.createContext<IAuthContext>({
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  user: undefined,
  isLoading: false,
  isAuthenticated: false,
  refreshToken: () => Promise.resolve(),
});

export const AuthProvider: React.FC<AuthConfig> = (props) => {
  const { children, clientId, scope } = props;

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [refreshBefore, setRefreshBefore] = useState<number>();
  const [, setRefreshTimeout] = useState<NodeJS.Timeout | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User | undefined>(undefined);

  // TODO: we need to actually wire this up to the org membership. This is just a placeholder.
  const [claims] = useState<Record<string, any>>({});

  const fetchUserInfo = useCallback(async () => {
    try {
      const rawData = await fetch(`${process.env.REACT_APP_BASE_URL}/api/me`, { credentials: 'include' });
      if (rawData.status !== 200) {
        setIsAuthenticated(false);
        throw new Error('Not authenticated');
      }

      const data = await rawData.json();
      const expiresAt = parseInt(data.accessTokenExpiresAt, 10) - 1000 * 60 * 5;
      setRefreshBefore(expiresAt);
      setUser({
        given_name: data.decodedIdToken.given_name,
        family_name: data.decodedIdToken.family_name,
        name: `${data.decodedIdToken.given_name} ${data.decodedIdToken.family_name}`,
        email: data.decodedIdToken.email,
        userId: data.decodedToken.sub,
      });

      try {
        TagManager.dataLayer({
          dataLayer: {
            event: 'identify',
            user: {
              userId: data.decodedToken.sub,
              firstName: data.decodedIdToken.given_name,
              lastName: data.decodedIdToken.family_name,
              email: data.decodedIdToken.email,
              organizations:
                data.memberships?.map((membership: any) => ({
                  organizationId: membership.organization.id,
                  organizationName: membership.organization.name,
                })) ?? [],
            },
          },
        });
      } catch (error) {}
      setIsAuthenticated(true);
    } catch (error) {
      setIsAuthenticated(false);
    }
  }, [setRefreshBefore, setUser, setIsAuthenticated]);

  useEffect(() => {
    setIsLoading(true);
    fetchUserInfo().then(() => {
      setIsLoading(false);
    });
  }, [setIsLoading, fetchUserInfo]);

  const login = useCallback(
    async (state = JSON.stringify({ returnTo: window.location.href }), impersonateOrg?: string) => {
      const queryParams = {
        state,
      } as Record<string, string>;
      if (!!clientId) {
        queryParams.clientId = clientId;
      }

      if (!!scope) {
        queryParams.scope = scope;
      }

      if (!!impersonateOrg) {
        queryParams.org = impersonateOrg;
      }

      const url = `${process.env.REACT_APP_BASE_URL}/api/auth/login?${new URLSearchParams(queryParams)}`;
      window.location.assign(url);
    },
    [clientId, scope],
  );

  const logout = useCallback(async () => {
    window.location.assign(`${process.env.REACT_APP_BASE_URL}/api/auth/logout`);
  }, []);

  const refreshToken = useCallback(async () => {
    await fetch(`${process.env.REACT_APP_BASE_URL}/api/auth/jwt-refresh`, {
      method: 'POST',
      credentials: 'include',
    });
    // Call me and change value
    await fetchUserInfo();
  }, [fetchUserInfo]);

  useEffect(() => {
    if (refreshBefore) {
      const waitTime = Math.abs(+new Date() - +new Date(refreshBefore));
      setRefreshTimeout((currentTimer) => {
        if (currentTimer) {
          clearTimeout(currentTimer);
        }
        return setTimeout(async () => {
          await refreshToken();
        }, waitTime);
      });
    }
  }, [refreshBefore, refreshToken]);

  const value = useMemo(
    () => ({
      login,
      logout,
      refreshToken,
      isLoading,
      isAuthenticated,
      user,
      claims,
    }),
    [login, logout, refreshToken, isLoading, isAuthenticated, user, claims],
  );

  if (!isAuthenticated) {
    return <AuthPage isLoading={isLoading} isAuthenticated={isAuthenticated} login={login} />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
