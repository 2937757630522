import { Box, Heading, Spinner, Table } from '@hyphen/hyphen-components';
import { useGetTeamMembersQuery } from '../../services/teams';
import DeleteTeamMember from './DeleteTeamMember';

interface TeamMembersListProps {
  organizationId: string;
  teamId: string;
  teamType?: string;
}

export const TeamMembersList = ({ organizationId, teamId, teamType }: TeamMembersListProps) => {
  const { isLoading, data: members } = useGetTeamMembersQuery({
    orgId: organizationId,
    id: teamId,
  });

  const isStaticTeam = teamType === 'static';

  const columnConfig = [
    {
      heading: 'Name',
      dataKey: 'name',
      render: (_cell: any, row: any) => (row.firstName || row.lastName ? `${row.firstName} ${row.lastName}` : ''),
    },
    {
      heading: 'email',
      dataKey: 'email',
    },
    {
      heading: '',

      render: (_cell: any, row: any) =>
        isStaticTeam && (
          <Box gap="sm" direction="row" justifyContent="flex-end">
            <DeleteTeamMember organizationId={organizationId} teamId={teamId} memberId={row.id} />
          </Box>
        ),
    },
  ];

  if (isLoading) {
    return (
      <Box padding="2xl">
        <Spinner />
      </Box>
    );
  }

  if (!isLoading && members?.data.length === 0) {
    return (
      <Box gap="xs" alignItems="center" padding="5xl 0">
        <Heading size="sm" as="h2">
          This team doesn't have any members
        </Heading>
        <Box fontSize="sm" color="secondary" padding="0 4xl">
          Members of a team will inherit access granted to the team
        </Box>
      </Box>
    );
  }

  return <Table rowKey="id" columns={columnConfig} rows={members?.data || []} isLoading={isLoading} />;
};
