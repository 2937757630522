import { Box } from '@hyphen/hyphen-components';
import { Helmet } from 'react-helmet';

import PageHeader from '../../../components/PageHeader';
import { Route, Routes, useParams } from 'react-router-dom';
import { ScrollableMainLayout } from '../../../components/ScrollableMainLayout';
import { Link } from 'react-router-dom';
import { useOrganization } from '../../../providers/OrganizationProvider';
import { TabNav, TabNavLink } from '../../../components/TabNav';
import { useGetToggleQuery } from '../../../services/toggle';
import Error from '../../../Error';
import { Project, useGetProjectQuery } from '../../../services/projects';
import { ToggleStatus } from '../../../components/toggles/ToggleStatus';

export const ToggleDetailContainer = () => {
  const { projectId, toggleId } = useParams<{ projectId: string; toggleId: string }>();
  const { organization } = useOrganization();

  const {
    data: project = {} as Project,
    error: projectError,
    isLoading: projectIsLoading,
  } = useGetProjectQuery({ projectId: projectId ?? '', organizationId: organization?.id ?? '' });

  const {
    data: toggle,
    error: toggleError,
    isLoading: toggleIsLoading,
  } = useGetToggleQuery({
    toggleKey: toggleId ?? '',
    projectId: projectId ?? '',
    organizationId: organization?.id || '',
  });

  const isLoading = projectIsLoading || toggleIsLoading;

  if (!isLoading && (toggleError || projectError)) {
    return <Error />;
  }

  if (toggle && project) {
    return (
      <ScrollableMainLayout fullWidth>
        <Helmet>
          <title>{toggle?.key}</title>
        </Helmet>

        <PageHeader
          eyebrow={<Link to={`/${organization?.id}/${project.alternateId}/toggles`}>{project.name}</Link>}
          title={
            <>
              {toggle?.key}
              <Box
                margin="0 0 0 sm"
                as="span"
                fontFamily="monospace"
                fontSize="sm"
                display="inline-block"
                padding="xs"
                color="secondary"
                background="secondary"
              >
                {toggle?.type}
              </Box>
            </>
          }
          description={toggle?.description}
        />

        <Box
          direction={{ base: 'column', tablet: 'row' }}
          gap="2xl"
          justifyContent="space-between"
          alignItems={{ base: 'stretch', tablet: 'center' }}
        >
          <TabNav>
            <TabNavLink to="">Status</TabNavLink>
            <TabNavLink to="settings">Settings</TabNavLink>
            <TabNavLink to="events">Events</TabNavLink>
            <TabNavLink to="evaluations">Evaluations</TabNavLink>
          </TabNav>
        </Box>

        <Routes>
          <Route path="/events" element={<>Toggle Events</>} />
          <Route path="/evaluations" element={<>Toggle Evaluations</>} />
          <Route path="/settings" element={<>Toggle Settings</>} />
          <Route path="/" element={<ToggleStatus toggle={toggle} project={project} />} />
        </Routes>
      </ScrollableMainLayout>
    );
  }
  return null;
};

export default ToggleDetailContainer;
