import { Box, Button, Heading } from '@hyphen/hyphen-components';
import { Guide } from '../guide/Guide';
import { GuideStep } from '../guide/GuideStep';
import { CodeBlock } from '../Codeblock';

const installScriptMacLinux = `sh -c "$(curl -fsSL https://cdn.hyphen.ai/install/install.sh)"`;
const installScriptWindows = `powershell -c "irm https://cdn.hyphen.ai/install/install.ps1 | iex"`;
const loginScript = `hx auth`;
const initScript = `hx init <app-name>`;
const pushScript = `hx push`;

export const EnvCliInstructions = () => {
  return (
    <>
      <Box
        padding="4xl"
        alignItems="center"
        gap="2xl"
        justifyContent="space-between"
        direction="row"
        background="brand-gradient-purple-reverse"
        radius="lg"
      >
        <Box gap="sm">
          <Heading as="h2" size="md" color="white">
            Set up environment secrets
          </Heading>
          <Box as="p" color="white" fontSize="sm">
            ENV stores, secures and controls access to passwords, API keys, tokens and other secrets for your
            applications.
          </Box>
        </Box>
        <Button variant="primary" iconSuffix="arrow-right" style={{ whiteSpace: 'nowrap' }} asChild>
          <a href="https://docs.hyphen.ai/docs/env-secrets-management" target="_blank" rel="noreferrer">
            ENV Setup Guide
          </a>
        </Button>
      </Box>
      <Guide title="ENV Quick Start">
        <GuideStep title="Install the CLI">
          <p>To get started, download and install the Hyphen CLI using the following command:</p>
          <Box gap="xs">
            <Box>macOS/Linux</Box>
            <CodeBlock>{installScriptMacLinux}</CodeBlock>
          </Box>
          <Box gap="xs">
            <Box>Windows</Box>
            <CodeBlock>{installScriptWindows}</CodeBlock>
          </Box>
        </GuideStep>
        <GuideStep title="Sign in to your Hyphen Account">
          <p>After installation, sign in to your Hyphen account by running:</p>
          <CodeBlock>{loginScript}</CodeBlock>
          <p>
            This will open a browser window to log in. If the window doesn’t open, copy and paste the URL from the
            terminal into your browser.
          </p>
        </GuideStep>
        <GuideStep title="Initialize Your Project">
          <p>
            To start using Hyphen ENV with your app, you need to initialize the app and set up encryption keys. This
            step creates a record of the app within your Hyphen organization and stores the secret encryption key on
            your machine in a <code>.hxkey</code> file.
          </p>
          <p>Navigate to your app’s directory and run:</p>
          <CodeBlock>{initScript}</CodeBlock>
          <p>
            You will receive a confirmation message indicating that the app has been successfully initialized,
            similar to the following:
          </p>
          <TerminalResponse>
            <div>App successfully initialized</div>
            <div>App Name: quick-start-app</div>
            <div>App AlternateId: quick-start-app</div>
            <div>App ID: app_6709694e54fc37367966d0ce</div>
            <div>Organization ID: org_66f30abb67ebc6bb0c5e0af7</div>
          </TerminalResponse>
        </GuideStep>
        <GuideStep title="Manage Your .env Files">
          <p>
            Once initialized, Hyphen will create several .env files in your app’s directory. Use these files to store
            environment-specific secrets:
          </p>
          <ol>
            <li>
              <code>.env</code>: default secrets stored here will apply to all environments
            </li>
            <li>
              <code>.env.development</code>: in the development environment, secrets defined here override secrets in
              .env
            </li>
            <li>
              <code>.env.production</code>: in the production environment, secrets defined here override secrets .env
            </li>
            <li>
              <code>.env.local</code>: in your local development environment, secrets defined here override the
              default secrets .env
            </li>
          </ol>
        </GuideStep>
        <GuideStep title="Encrypt and Push Secrets to Hyphen">
          <p>
            Once you’ve added your secrets to the appropriate .env files, you need to encrypt them and push them to
            Hyphen. Run the following command:
          </p>
          <CodeBlock>{pushScript}</CodeBlock>
          <p>
            You will receive confirmation for each environment that’s been pushed. If you’ve updated the default,
            development, and production environments, the response will look like:
          </p>
          <TerminalResponse>
            <div>Local environments: default, development, production</div>
            <div>Environments pushed: default, development, production</div>
          </TerminalResponse>
          <p>
            <strong>Note:</strong> <code>.env.local</code> is never pushed to Hyphen.
          </p>
        </GuideStep>
      </Guide>
    </>
  );
};

const TerminalResponse = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <Box
    gap="2xs"
    background="secondary"
    fontFamily="monospace"
    padding="sm"
    radius="sm"
    fontSize="xs"
    className={className}
  >
    {children}
  </Box>
);
