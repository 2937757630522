import { Helmet } from 'react-helmet';
import { Box, Spinner } from '@hyphen/hyphen-components';
import { useGetIntegrationsQuery } from '../../../services/integrations';
import { Organization } from '../../../services/organization';
import PageHeader from '../../../components/PageHeader';
import { useOrganization } from '../../../providers/OrganizationProvider';
import { ConnectedIntegrations } from '../../../components/integrations/ConnectedIntegrations';
import './Integrations.css';
import { ScrollableMainLayout } from '../../../components/ScrollableMainLayout';
import { IntegrationType } from '../../../types/integrations';
import { IntegrationCatalogItem } from '../../../components/integrations/catalog/IntegrationCatalogItem';
import EmptyList from '../../../components/common/EmptyList';

export const IntegrationsOverview = () => {
  const { organization = {} as Organization } = useOrganization();
  const { isLoading, data } = useGetIntegrationsQuery(organization.id);

  return (
    <ScrollableMainLayout>
      <Helmet>
        <title>Integrations</title>
      </Helmet>
      <Box gap="5xl">
        <PageHeader
          title="Integrations"
          description="Supercharge your workflow and connect Hyphen to tools you use everyday"
        />
        {isLoading ? (
          <Spinner />
        ) : data && data.data.length > 0 ? (
          <ConnectedIntegrations integrations={data.data} organizationId={organization.id} />
        ) : (
          <EmptyList
            title="No connected integrations"
            description="Browse our integration library below and connect your tools to Hyphen"
          />
        )}
        <Box gap="3xl">
          <Box as="h2" fontSize="md" fontWeight="medium">
            Featured Integrations
          </Box>
          <Box
            display="grid"
            direction="row"
            wrap
            gap={{ base: 'xl', desktop: '3xl' }}
            className="one-column two-column-tablet three-column-desktop"
            margin="0 0 xl 0"
          >
            <IntegrationCatalogItem
              organizationId={organization.id}
              type={IntegrationType.googleWorkspace}
              variant="featured"
            />
            <IntegrationCatalogItem
              organizationId={organization.id}
              type={IntegrationType.googleCloud}
              variant="featured"
            />
            <IntegrationCatalogItem
              organizationId={organization.id}
              type={IntegrationType.azure}
              variant="featured"
            />
          </Box>

          <Box gap={{ base: 'xl', desktop: '3xl' }} display="grid" className="one-column two-column-tablet">
            <IntegrationCatalogItem organizationId={organization.id} type={IntegrationType.aws} />
            <IntegrationCatalogItem organizationId={organization.id} type={IntegrationType.azure} />
            <IntegrationCatalogItem organizationId={organization.id} type={IntegrationType.github} />
            <IntegrationCatalogItem organizationId={organization.id} type={IntegrationType.googleCloud} />
            <IntegrationCatalogItem organizationId={organization.id} type={IntegrationType.googleWorkspace} />
            <IntegrationCatalogItem organizationId={organization.id} type={IntegrationType.slack} />
            <IntegrationCatalogItem organizationId={organization.id} type={IntegrationType.office365} />
            <IntegrationCatalogItem organizationId={organization.id} type={IntegrationType.incidentIo} />
            <IntegrationCatalogItem organizationId={organization.id} type={IntegrationType.npm} />
          </Box>
        </Box>
      </Box>
    </ScrollableMainLayout>
  );
};

export default IntegrationsOverview;
