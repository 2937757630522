import { Helmet } from 'react-helmet';
import { Box, Spinner } from '@hyphen/hyphen-components';
import PageHeader from '../../components/PageHeader';
import { ScrollableMainLayout } from '../../components/ScrollableMainLayout';
import { useOrganization } from '../../providers/OrganizationProvider';
import { Organization } from '../../services/organization';
import { Route, Routes, useParams } from 'react-router-dom';
import { Project, useGetProjectQuery } from '../../services/projects';
import ClickToCopy from '../../components/common/ClickToCopy';
import { App, useGetAppsQuery } from '../../services/apps';
import { ListResponse } from '../../services/types';
import { Environment, useGetProjectEnvironmentsQuery } from '../../services/environments';
import Error from '../../Error';
import ProjectSettings from './Projects/ProjectSettings';
import ProjectOverview from './Projects/ProjectOverview';
import { TabNavLink, TabNav } from '../../components/TabNav';
import ProjectAccess from './Projects/ProjectAccess';
import { Link } from 'react-router-dom';
import ToggleDashboard from './Toggles/ProjectToggles';
import ProjectConnections from '../../components/projects/ProjectConnections';

export default function ProjectContainer() {
  const { projectId } = useParams<{ projectId: string }>();
  const { organization = {} as Organization } = useOrganization();

  const {
    data: project = {} as Project,
    error: projectError,
    isLoading: projectIsLoading,
  } = useGetProjectQuery({ projectId: projectId ?? '', organizationId: organization.id ?? '' });

  const {
    data: apps = {} as ListResponse<App>,
    error: appsError,
    isLoading: appsIsLoading,
  } = useGetAppsQuery({ organizationId: organization.id, projectIds: projectId }) || {};

  const {
    data: environments = {} as ListResponse<Environment>,
    error: environmentsError,
    isLoading: environmentsIsLoading,
  } = useGetProjectEnvironmentsQuery({
    organizationId: organization.id,
    projectId: projectId,
  }) || {};
  const environmentsData = environments?.data || [];

  const projectData = {
    ...project,
    apps: apps.data,
    environments: environmentsData,
  };

  const isLoading = projectIsLoading || appsIsLoading || environmentsIsLoading;

  if (!isLoading && (projectError || appsError || environmentsError)) return <Error />;

  return (
    <ScrollableMainLayout>
      {isLoading ? (
        <Box gap="md" direction="row" alignItems="center">
          <Spinner size="md" />
          Loading...
        </Box>
      ) : (
        <>
          <Helmet>
            <title>{project.name}</title>
          </Helmet>
          <PageHeader
            eyebrow={<Link to={`/${organization.id}`}>Projects</Link>}
            flex="auto"
            title={project.name}
            description={<ClickToCopy text={project.alternateId} fontSize="xs" />}
          />
          <TabNav>
            <TabNavLink to="">Overview</TabNavLink>
            <TabNavLink to="toggles">Toggles</TabNavLink>
            <TabNavLink to="connections">Connections</TabNavLink>
            <TabNavLink to="access">Access</TabNavLink>
            <TabNavLink to="settings">Settings</TabNavLink>
          </TabNav>

          <Routes>
            <Route path="" element={<ProjectOverview project={projectData} />} />
            <Route path="toggles" element={<ToggleDashboard project={projectData} />} />
            <Route
              path="connections"
              element={<ProjectConnections project={projectData} organization={organization} />}
            />
            <Route path="access" element={<ProjectAccess project={projectData} />} />
            <Route path="settings" element={<ProjectSettings project={projectData} />} />
          </Routes>
        </>
      )}
    </ScrollableMainLayout>
  );
}
