import { Box, Card } from '@hyphen/hyphen-components';

import { Toggle } from '../../types/toggle';
import ToggleListItem from './ToggleListItem';
import { Project } from '../../services/projects';
import { EmptyToggleList } from './EmptyToggleList';

interface ToggleListProps {
  toggles: any;
  project?: Project;
}

// Main toggle list component
const ToggleList: React.FC<ToggleListProps> = ({ project, toggles }) => {
  return (
    <>
      {toggles.data.length > 0 ? (
        <Box width="100" gap="lg">
          <Card>
            {toggles.data.map((toggle: Toggle) => (
              <ToggleListItem key={toggle.key} toggle={toggle} project={project} />
            ))}
          </Card>
        </Box>
      ) : (
        <EmptyToggleList />
      )}
    </>
  );
};

export default ToggleList;
