import { Box, Button, Modal, toast } from '@hyphen/hyphen-components';
import { useCallback, useRef } from 'react';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { InferType } from 'yup';

import { ApiError } from '../ApiError';
import { useCreateMemberMutation } from '../../services/member';
import { useReplaceTeamMembersMutation } from '../../services/teams';
import { ModalProps } from '../types/modal';
import { CreateMemberData, MemberSelect } from '../types/member';
import { SelectMember } from './SelectOrCreateMembers';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';
import { EntityNames } from '@hyphen/nucleus/dist/types';
import { Organization } from '../../services/organization';
import { useOrganization } from '../../providers/OrganizationProvider';

const addMemberSchema = yup.object().shape({
  members: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required(),
        label: yup.string().required(),
      }),
    )
    .min(1, 'At least one member is required'),
});

type AddMemberSchema = InferType<typeof addMemberSchema>;

const errorMessages = {
  default: 'Failed to add member',
};

export const AddMemberModal = ({ data }: ModalProps) => {
  const { id } = useParams<{ id: any }>();
  const { organization = {} as Organization } = useOrganization();
  const modalRef = useRef<HTMLFormElement>(null);

  const navigate = useNavigate();

  const [createMember, { error: createError }] = useCreateMemberMutation();
  const [replaceTeamMembers, { error: replaceError }] = useReplaceTeamMembersMutation();

  const ability = useOrganizationAbilityContext();
  const canInviteMembersToOrganization = ability.can('create', EntityNames.Member);

  const handleDismiss = useCallback(() => {
    navigate(`/${organization.id}/teams/${id}`);
  }, [navigate, organization.id, id]);

  const handleInviteMember = useCallback(
    async (newMembers: MemberSelect[]) => {
      const responses = (await Promise.all(
        newMembers.map(
          async (member) =>
            await createMember({
              email: member.label,
              organizationId: data.organization.id,
            }),
        ),
      )) as CreateMemberData[];

      if (responses.length) {
        return responses.map((response) => response.data?.id);
      }

      return [];
    },
    [createMember, data.organization.id],
  );

  const handleOnSubmit = useCallback(
    async (values: AddMemberSchema) => {
      let memberIds =
        values.members
          ?.filter((member: MemberSelect) => !member.__isNew__)
          .map((member: MemberSelect) => member.value) ?? [];

      const newMembers = values.members?.filter((member: MemberSelect) => member.__isNew__);
      const newMemberIds = newMembers?.length ? await handleInviteMember(newMembers) : [];

      if (newMemberIds.length) {
        memberIds = [...memberIds, ...newMemberIds];
      }

      const { error } = await replaceTeamMembers({
        orgId: data.organization.id,
        teamId: id,
        data: { memberIds },
      });

      if (!error) {
        toast.success('Member added', { duration: 5000 });
        handleDismiss();
      }
    },
    [handleInviteMember, replaceTeamMembers, data.organization.id, id, handleDismiss],
  );

  return (
    <Modal isOpen onDismiss={handleDismiss} maxWidth="9xl" ref={modalRef}>
      <Formik initialValues={{ members: [] }} validationSchema={addMemberSchema} onSubmit={handleOnSubmit}>
        {({ isSubmitting, errors }) => (
          <Form noValidate>
            <Box gap={{ base: '2xl', tablet: '4xl' }}>
              <Modal.Header id="addMemberModal" title="Add Member" onDismiss={handleDismiss} />
              <Modal.Body>
                <SelectMember
                  label="Members"
                  name="members"
                  isMulti
                  isCreatable={canInviteMembersToOrganization}
                  error={errors.members}
                  portalTarget={modalRef.current}
                />
                {createError || replaceError ? (
                  <ApiError error={createError || replaceError} customMessages={errorMessages} />
                ) : null}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" type="button" onClick={handleDismiss} isDisabled={isSubmitting}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit" isLoading={isSubmitting}>
                  Save
                </Button>
              </Modal.Footer>
            </Box>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
