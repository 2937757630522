import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  Box,
  Heading,
  Card,
  Button,
  DrawerProvider,
  DrawerTrigger,
  toast,
  useOpenClose,
  Modal,
  Icon,
} from '@hyphen/hyphen-components';
import React, { useCallback, useState } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { Toggle } from '../../types/toggle';
import { Link, useParams } from 'react-router-dom';
import { DraggableTarget } from './DraggableTarget';
import LogicalOperator from './LogicalOperator';
import { parseJsonLogic } from '../../utils/parseJsonLogic';
import { useUpdateToggleTargetsMutation } from '../../services/toggle';
import { useOrganization } from '../../providers/OrganizationProvider';
import { Organization } from '../../services/organization';
// import EditTargetContextDrawer from '../../../components/toggle/EditTargetContextDrawer';

const Targets = ({ toggle }: { toggle: Toggle }) => {
  const { projectId } = useParams<{ projectId: string }>();
  const { organization = {} as Organization } = useOrganization();
  const { targets, type: returnType, defaultValue } = toggle;
  const { isOpen, handleOpen: showErrorModal, handleClose } = useOpenClose();

  const [updateToggleTargets] = useUpdateToggleTargetsMutation();

  // dnd-kit requires unique ids for each item
  const targetsWithIds = targets.map((target, index) => ({
    ...target,
    id: `${Date.now()}-${Math.random()}-${index}`,
    parsedLogic: parseJsonLogic(target.logic),
  }));

  const [items, setItems] = useState(targetsWithIds);
  const [previousItems, setPreviousItems] = useState(targetsWithIds);
  const [isDragging, setIsDragging] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = () => {
    setIsDragging(true);
    setPreviousItems(items);
  };

  const handleDragEnd = useCallback(
    async (event: DragEndEvent) => {
      const { active, over } = event;
      if (over && active.id !== over.id) {
        try {
          const newTargetOrder = arrayMove(
            items,
            items.findIndex((item) => item.id === active.id),
            items.findIndex((item) => item.id === over.id),
          );

          if (JSON.stringify(newTargetOrder) !== JSON.stringify(items)) {
            setItems(newTargetOrder);

            const { error, data } = await updateToggleTargets({
              organizationId: organization.id,
              projectId: projectId || '',
              toggleKey: toggle.key,
              body: { targets: newTargetOrder },
            });

            if (!error && data) {
              setIsDragging(false);
              toast.success('Targets updated');
            } else {
              throw new Error('Failed to update targets');
            }
          }
        } catch (err) {
          setItems(previousItems);
          showErrorModal();
        }
      }
    },
    [items, organization.id, previousItems, projectId, showErrorModal, toggle.key, updateToggleTargets],
  );

  return (
    <Box background="secondary" padding="0" radius="md">
      <Box
        direction="row"
        alignItems="center"
        gap="lg"
        borderWidth="0 0 sm 0"
        borderColor="default"
        padding={{ base: '2xl', tablet: '3xl' }}
      >
        <Box flex="auto">
          <Heading as="h2" size="sm">
            Targets
          </Heading>
        </Box>
        <AddTargetDrawer returnType={returnType} />
      </Box>
      <Box as="ol" gap={{ base: 'lg', desktop: '2xl' }} padding={{ base: '2xl', tablet: '3xl' }}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            {items.map((targetContext: any, index: number) => (
              <Fragment key={index}>
                <DraggableTarget key={targetContext.id} returnType={returnType} targetContext={targetContext} />
                {index < items.length - 1 && <LogicalOperator isDragging={isDragging} operator="OR" />}
              </Fragment>
            ))}
          </SortableContext>
          {items.length > 0 && <LogicalOperator isDragging={false} operator="ELSE" />}
          <DefaultReturnValueCard returnType={returnType} defaultReturnValue={defaultValue} />
        </DndContext>
      </Box>
      <Modal isOpen={isOpen} onDismiss={handleClose} background="danger" maxWidth="9xl" ariaLabelledBy="orderError">
        <Modal.Body gap="lg" textAlign="center" alignItems="center">
          <Icon name="t-warning" size="4xl" color="danger" />
          <Box as="p" id="orderError" margin="0 0 lg 0">
            Failed to update targets. Reverting to previous order.
          </Box>
          <Button onClick={handleClose}>Acknowledge</Button>
        </Modal.Body>
      </Modal>
    </Box>
  );
};

const AddTargetDrawer = ({ returnType }: { returnType: any }) => {
  return (
    <DrawerProvider>
      <DrawerTrigger asChild>
        <Button size="sm" variant="primary">
          Add Target
        </Button>
      </DrawerTrigger>
      {/* <EditTargetContextDrawer returnType={returnType} /> */}
    </DrawerProvider>
  );
};

const DefaultReturnValueCard: React.FC<{ returnType: any; defaultReturnValue: any }> = React.memo(
  ({ returnType, defaultReturnValue }) => (
    <Box as="li" fontSize="sm" direction="row" alignItems="center" gap="lg">
      <Card fontFamily="monospace" shadow="0">
        <Box direction="row" alignItems="center" padding="2xl" gap={{ base: 'sm', tablet: 'xl', desktop: '2xl' }}>
          <Box flex="auto" padding="sm lg" borderColor="subtle" borderWidth="sm" radius="sm">
            <Box>DEFAULT RETURN VALUE</Box>
          </Box>
          <Box
            display="inline-block"
            radius="sm"
            background="tertiary"
            padding="sm"
            alignSelf={{ base: 'flex-start', tablet: 'center' }}
          >
            RETURNS
          </Box>
          <Box
            radius="sm"
            maxWidth="40"
            minWidth="6xl"
            borderColor="subtle"
            borderWidth="sm"
            background="primary"
            padding="sm lg"
            justifyContent="center"
            alignItems="flex-end"
            style={{ wordBreak: 'break-word' }}
            color={
              returnType === 'boolean' && defaultReturnValue === true
                ? 'success'
                : returnType === 'boolean' && defaultReturnValue === false
                ? 'danger'
                : 'base'
            }
          >
            {defaultReturnValue.toString()}
          </Box>
        </Box>
      </Card>
      <Button asChild variant="secondary" size="sm">
        <Link to="settings">Edit</Link>
      </Button>
    </Box>
  ),
);

export default Targets;
