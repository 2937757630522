import { IncidentIoIntegration } from '../../../types/integrations';
import { IntegrationCard } from './common/IntegrationCard';

interface IncidentIoDetailsProps {
  integration: IncidentIoIntegration;
}

export const IncidentIoDetails = ({ integration }: IncidentIoDetailsProps) => {
  return (
    <IntegrationCard
      status="Connected"
      details={[
        { label: 'Organization Name', value: integration.config.organizationName },
        { label: 'Organization Dashboard Url', value: integration.config.dashboardUrl },
      ]}
      integration={integration}
    />
  );
};

export default IncidentIoDetails;
