import { Helmet } from 'react-helmet';
import { Project } from '../../../services/projects';
import ToggleList from '../../../components/toggles/ToggleList';
import { useOrganization } from '../../../providers/OrganizationProvider';
import { useGetTogglesQuery } from '../../../services/toggle';
import { Organization } from '../../../services/organization';
import { Button, Spinner } from '@hyphen/hyphen-components';
import { ApiError } from '../../../components/ApiError';
import { Link } from 'react-router-dom';
import { useOrganizationAbilityContext } from '../../../components/auth/OrganizationAbilityProvider';
import { EntityNames } from '@hyphen/nucleus/dist/types';

export default function ProjectToggles({ project }: { project?: Project }) {
  const { organization = {} as Organization } = useOrganization();
  const ability = useOrganizationAbilityContext();

  const canCreateToggle = project
    ? ability.can('update', project) && ability.can('create', EntityNames.Toggle)
    : ability.can('create', EntityNames.Toggle);

  const { data, isLoading, error } = useGetTogglesQuery({
    organizationId: organization.id,
    projectId: project?.alternateId || '',
  });

  if (error) {
    return <ApiError error={error} />;
  }

  if (isLoading) {
    <Spinner />;
  }

  return (
    <>
      <Helmet>
        <title>{project ? `[${project.name}] Feature Toggles` : 'Feature Toggles'}</title>
      </Helmet>
      {canCreateToggle && (
        <div>
          <Button asChild size="sm">
            <Link to="create">Create a Toggle</Link>
          </Button>
        </div>
      )}
      {data && <ToggleList toggles={data} project={project} />}
    </>
  );
}
