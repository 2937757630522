import { IntegrationSetupForm } from '../common/IntegrationSetupForm';
import { IncidentIoIntegrationConfig, IntegrationType } from '../../../../types/integrations';
import * as yup from 'yup';

interface IncidentIoSetupFormProps {
  organizationId: string;
}

const incidentIoSetupFormSchema = yup.object().shape({
  token: yup.string().required('Required'),
});

const incidentIoFormConfig = [
  { id: 'token', type: 'text', label: 'Api Key', initialValue: '' },
];

export const IncidentIoSetupForm = ({ organizationId }: IncidentIoSetupFormProps) => (
  <IntegrationSetupForm
    organizationId={organizationId}
    validationSchema={incidentIoSetupFormSchema}
    formConfig={incidentIoFormConfig}
    configMapper={(values: IncidentIoIntegrationConfig) => ({
      token: values.token,
    })}
    integrationType={IntegrationType.incidentIo}
  />
);
