import { Button } from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { Guide } from '../../../guide/Guide';
import { GuideStep } from '../../../guide/GuideStep';
import { NpmSetupForm } from './NpmSetupForm';

interface NpmSetupGuideProps {
  organizationId: string;
}

export const NpmSetupGuide = ({ organizationId }: NpmSetupGuideProps) => {
  return (
    <Guide title="Setup Guide">
      <GuideStep title="Create or sign into your NPM account">
        <p>
          <Button asChild iconSuffix="launch-app">
            <a href="https://www.npmjs.com/login" target="_blank" rel="noopener noreferrer">
              Sign in to NPM
            </a>
          </Button>
        </p>
        <p>or</p>
        <p>
          <Button asChild iconSuffix="launch-app">
            <a href="https://www.npmjs.com/signup" target="_blank" rel="noopener noreferrer">
              Create a new NPM account
            </a>
          </Button>
        </p>
      </GuideStep>

      <GuideStep title="Generate Access Token">
        <p>
          <Link to="https://docs.npmjs.com/creating-and-viewing-access-tokens" target="_blank">
            Follow the NPM documentation to create an access token
          </Link>
        </p>
        <p>Make sure to grant the following permissions:</p>
        <ul>
          <li>Read and write organization members</li>
          <li>Read and write teams</li>
          <li>Read and write packages</li>
        </ul>
      </GuideStep>

      <GuideStep title="Connect NPM Organization">
        <NpmSetupForm organizationId={organizationId} />
      </GuideStep>
    </Guide>
  );
};
