import { Box } from '@hyphen/hyphen-components';

interface LogicalOperatorProps {
  operator: 'AND' | 'OR' | 'ELSE';
  isDragging: boolean;
}

const LogicalOperator = ({ operator, isDragging }: LogicalOperatorProps) => {
  return isDragging ? (
    <Box height="32px" width="100" justifyContent="center">
      <Box
        borderColor="default"
        borderWidth="sm 0 0 0"
        height="1px"
        width="100%"
        style={{ borderStyle: 'dashed' }}
      />
    </Box>
  ) : (
    <Box padding="0" fontSize="sm" fontFamily="monospace" display="block">
      <Box display="inline-block" fontWeight="bold" radius="sm" background="tertiary" padding="sm">
        {operator}
      </Box>
    </Box>
  );
};

export default LogicalOperator;
